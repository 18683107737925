<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords"
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         :item-class="itemRowBackground"
         >
         <template v-slot:top>
            <v-toolbar flat style="height: 68px; display: grid; align-content: center;">
               <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-text-field v-model="table.search" placeholder="Search" outlined hide-details dense clearable single-line flat
                     prepend-inner-icon="mdi-magnify"
                     class="mr-3" style="max-width: 280px;"
                     />
                  <v-select v-model="table.filter" :items="registrations" placeholder="Approval Status" outlined hide-details dense multiple clearable
                     :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                     class="mr-3 hidden-sm-and-down" style="max-width: 280px;"
                     >
                     <template v-slot:selection="{ item, index }">
                        <span v-if="index < 1">{{ index === 0 ? item.text : `, ${item.text}` }}</span>
                        <span v-if="index === 1" class="grey--text ml-2">
                           ( +{{ table.filter.length - 1 }} more )
                        </span>
                     </template>
                  </v-select>
               </template>
               <v-spacer />
               <v-btn large color="primary" depressed @click="activity.id = 0">
                  Register
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.contact`]="{ item }">
            {{ item.phone || item.email }}
         </template>
         <template v-slot:[`item.organization`]="{ item }">
            {{ organizationText(item.organization) }}
         </template>
         <template v-slot:[`item.floor`]="{ item }">
            {{ floorText(item.floor) }}
         </template>
         <template v-slot:[`item.since`]="{ item }">
            {{ item.Activities.find(j => ['Register', 'Enrolled'].includes(j.name)).since }}
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="item.activity === 'Accepted'" size="28" class="ml-2" :disabled="$moment(item.until).isBefore($moment())" @click="printRecord(item)">
               <!-- v-if="item.activity === 'Accepted' && operator.name === (item.Activities.find(j => ['Register', 'Enrolled'].includes(j.name)) || {}).operator" disabled -->
               mdi-printer-pos
            </v-icon>
            <v-icon class="ml-2" @click="modifyRecord(item)">
               <!-- :disabled="item.disabled" -->
               mdi-card-account-details
            </v-icon>
            <v-icon class="ml-2" color="red darken-2" :disabled="item.disabled || ['Enrolled', 'Accepted', 'Rejected'].includes(item.activity)" @click="updateRecord('Rejected', item)">
               mdi-minus-circle
            </v-icon>
            <v-icon class="ml-2" color="teal darken-1" :disabled="item.disabled || ['Enrolled', 'Accepted', 'Rejected'].includes(item.activity)" @click="updateRecord('Accepted', item)">
               mdi-check-bold
            </v-icon>
            <!-- <v-icon class="ml-2"> -->
               <!-- v-if="$hasRole(item.kind.toLowerCase(), 'RU')" @click="modifyRecord(item)" -->
               <!-- {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }} -->
               <!-- mdi-eye
            </v-icon> -->
            <!-- <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon> -->
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <!-- Detail Dialog -->
      <v-dialog v-model="dialog.show" max-width="438px">
         <v-card class="pa-4" color="white">
            <v-card-title class="grey lighten-5 pa-0 justify-space-between">
               <img v-if="dialog.Face" :src="setUrlFromImage(dialog.Face)" alt="Face" width="160" height="160" style="object-fit: cover;" @error="() => { dialog.Face = '' }">
               <v-icon v-else size="160">
                  mdi-image
               </v-icon>
               <img v-if="dialog.Card" :src="setUrlFromImage(dialog.Card)" alt="Card" width="230" height="160" style="object-fit: cover;" @error="() => { dialog.Card = '' }">
               <v-icon v-else size="205" class="mr-4" style="height: 180px;">
                  mdi-image-area
               </v-icon>
            </v-card-title>
            <v-card-text class="px-0">
               <v-container class="px-1">
                  <v-row>
                     <v-col cols="12" class="pb-0">
                        <v-text-field :value="dialog.name" label="Name" hide-details readonly />
                     </v-col>
                     <v-col cols="12" md="4" class="pb-0">
                        <v-text-field :value="`V-${dialog.identity.slice(9)}`" label="Badge" hide-details readonly />
                     </v-col>
                     <v-col cols="12" md="4" class="pb-0">
                        <v-text-field :value="$moment(dialog.until ? dialog.until : undefined).format('YYYY-MM-DD')" label="Valid Date" prepend-inner-icon="mdi-calendar-today" hide-details readonly />
                     </v-col>
                     <v-col cols="12" md="4" class="pb-0">
                        <v-menu ref="untilMenuTime" v-model="until.timeMenu" :close-on-content-click="false" min-width="290px" max-width="290px" :disabled="dialog.activity !== 'Register'"
                           :nudge-right="40" :return-value.sync="until.timeValue"
                           transition="scale-transition" offset-y
                           >
                           <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="until.timeValue" label="Until Time" placeholder="Until Time" hide-details readonly
                                 prepend-inner-icon="mdi-clock-outline"
                                 v-bind="attrs" v-on="on"
                                 />
                           </template>
                           <v-time-picker v-if="until.timeMenu" v-model="until.timeValue" full-width format="24hr" use-seconds @click:second="$refs.untilMenuTime.save(until.timeValue)" />
                        </v-menu>
                     </v-col>
                     <v-col cols="12" class="pb-0">
                        <v-text-field v-model="dialog.message" label="Message to Visitor" hide-details />
                     </v-col>
                  </v-row>
               </v-container>
            </v-card-text>
            <v-card-actions class="px-1 pb-2">
               <v-spacer />
               <v-btn color="grey darken-2" text width="90" @click="updateRecord">
                  {{ (dialog.disabled || ['Enrolled', 'Accepted', 'Rejected'].includes(dialog.activity)) ? 'Close' : 'Cancel' }}
               </v-btn>
               <template v-if="!(dialog.disabled || ['Enrolled', 'Accepted', 'Rejected'].includes(dialog.activity))">
                  <v-btn color="red darken-2" text outlined width="90" @click="updateRecord('Rejected', dialog)">
                     Reject
                  </v-btn>
                  <v-btn color="teal darken-1" text outlined width="90" @click="updateRecord('Accepted', dialog)">
                     Accept
                  </v-btn>
               </template>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import * as QRCodeStyling from 'qr-code-styling'
import { version } from '../../../package'
import { imageFromUrl, blobImageToBase64, encrypt } from '@/utils/nirtara'
const empty = { show: false, Face: '', Card: '', identity: '', message: '' }
export default {
   name: 'NApprovalTable',

   props: {
      activity: { type: Object },
      operator: { type: Object },
      records: { type: Array },
      organizations: { type: Array },
      floors: { type: Array },
   },

   data: () => ({
      version: version.replace(/\D/g, '').slice(-5),
      columns: [
         // { text: 'Badge', value: 'identity', width: 115, align: 'center' },
         { text: 'Visitor', value: 'name' },
         { text: 'Contact', value: 'contact' },
         { text: 'Tenant', value: 'organization' },
         { text: 'Floor', value: 'floor', width: 150 },
         // { text: 'Email', value: 'email', width: 135 },
         // { text: 'Reason', value: 'reason', width: 135 },
         { text: 'Since', value: 'since', width: 170 },
         { text: 'Status', value: 'activity', width: 100 },
         { text: 'Actions', value: 'actions', width: 150, align: 'end pl-0', sortable: false },
      ],
      dialog: Object.assign({}, empty),
      table: { search: '', filter: [] },
      until: { timeMenu: false, timeValue: null },
      registrations: [],
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            var filteredSince = j.Activities[0].since && j.Activities[0].since.indexOf(search) !== -1
            return (!search || filteredName || filteredSince) &&
                   (!this.table.filter.length || this.table.filter.includes(j.activity))
         })
      },
      organizationText () {
         return organization => (this.organizations.find(j => j.identity === organization) || { name: organization }).name
      },
      floorText () {
         return floor => (this.floors.find(j => j.identity === floor) || { name: floor }).name
      },
   },
   created () {
      this.registrations = [
         { text: 'Enrolled' },
         { text: 'Register' },
         { text: 'Rejected' },
         { text: 'Accepted' },
      ]
   },
   mounted () {
   },
   methods: {
      itemRowBackground: function (item) {
         switch (item.activity) {
            default: return item.activity === 'Enrolled' ? 'grey--text text--lighten-1' : ''
            // case 'Ammended':
            // case 'Accepted': return 'accent lighten-5'
            // case 'Verified': return 'amber lighten-5'
            // case '': return 'error lighten-5'
         }
      },
      modifyRecord (item) {
         // console.log(`modifyRecord ( item.until = ${item.until} )`, item && JSON.parse(JSON.stringify(item)))
         // this.until.timeValue = item.until ? this.$moment(item.until).format('HH:mm:ss') : '19:00:00'
         const moments = [
            this.$moment().add((30 - (this.$moment().minute() % 30)), 'minutes').set({ second: 0, millisecond: 0 }),
            this.$moment().set({ hour: 19, minute: 0, second: 0, millisecond: 0 }),
         ]
         this.until.timeValue = item.until ? this.$moment(item.until).format('HH:mm:ss') : this.$moment.max(moments).format('HH:mm:ss')
         Object.assign(this.dialog, { show: true }, JSON.parse(JSON.stringify(item)))
      },
      updateRecord (activity, item) {
         if (['Accepted', 'Rejected'].includes(activity)) {
            const record = Object.assign({}, item, { activity: activity })
            if (activity === 'Accepted') {
               const valid = item.valid || this.$moment().format('YYYY-MM-DD HH:mm:ss')
               record.valid = valid
               record.until = `${valid.slice(0, 10)} ${this.until.timeValue || '19:00:00'}`
            }
            // console.log(`updateRecord (${activity})`, JSON.parse(JSON.stringify(record)))
            this.records.find(j => j.id === record.id).disabled = true
            // const ipV4 = this.$store.state.ipV4 || (await (await fetch('https://api.ipify.org?format=json')).json()).ip
            record.Activities.unshift({ Activity: [], name: activity, since: this.$moment().format('YYYY-MM-DD HH:mm:ss'), operator: this.operator.identity || 'None', ipV4: this.$store.state.ipV4 })
            // console.log(`updateRecord (${activity})`, JSON.parse(JSON.stringify(record)))
            this.$emit('update', record)
         }
         this.$nextTick(() => {
            this.dialog = Object.assign({}, empty)
            this.until.timeValue = ''
         })
      },
      async printRecord (item) {
         // console.log('printRecord (item)', JSON.parse(JSON.stringify(item)))
         const qrCode = new QRCodeStyling({
            width: 280,
            height: 280,
            margin: 12,
            type: 'svg',
            dotsOptions: { type: 'classy' },
            data: encrypt(item.id),
            qrOptions: { errorCorrectionLevel: 'Q' },
         })
         const blob = await qrCode.getRawData()
         const base64 = await blobImageToBase64(blob)
         // console.log('printRecord (base64)', `${base64.substr(0, 32)}....`)
         var docDefinition = {
            info: {
               title: `${this.$store.state.setting.global.name} v${this.version.slice(0, 3)}.${this.version.slice(3, 4)}.${this.version.slice(-1)}`,
               author: this.$store.state.setting.global.copyright,
               subject: this.$store.state.setting.global.title,
               keywords: 'NIRTARA, Visitor, Management',
            },
            pageSize: 'A6',
            pageMargins: [60, 42, 60, 24],
            content: [
               { text: 'Your Visitor ID', style: 'small' },
               { text: `V-${(item.identity).slice(9)}`, style: 'badge', margin: [0, 6, 0, 9] },
               { text: 'Accepted By', style: 'small' },
               { text: (this.organizations.find(j => j.identity === item.organization) || { name: item.organization }).name.toUpperCase(), margin: [0, 6, 0, 3] },
               { text: item.message, style: 'small' },
               { image: base64, fit: [200, 200], alignment: 'center' },
               { text: `Perhatian: qrcode berlaku pada hari ${this.$moment(item.valid).format('dddd, DD MMMM YYYY, HH:mm:ss')} s/d ${this.$moment(item.until).format('HH:mm:ss')}`, style: 'small' },
            ],
            defaultStyle: { fontSize: 13, alignment: 'center', bold: true },
            styles: {
               small: {
                  fontSize: 9,
                  alignment: 'center',
               },
               badge: {
                  fontSize: 24,
                  alignment: 'center',
                  bold: true,
               },
            },
         }
         this.$pdfMake.createPdf(docDefinition).download(`NVM-50-WEB-V-${(item.identity).slice(9)}-${this.$moment().format('YYYYMMDD-HHmmss')}.pdf`)
         // https://pdfmake.github.io/docs/0.1/getting-started/client-side/methods/
         // this.$pdfMake.createPdf(docDefinition).print()
         // const win = window.open('', '_blank')
         // this.$pdfMake.createPdf(docDefinition).print({}, win)
         // win.close()
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item .v-list-item__title {
   font-size: 0.925rem;
   line-height: 1.1rem;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
   height: 44px;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
.v-btn:not(.v-btn--round).v-size--large {
   height: 44px;
   min-width: 150px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   background-color: #e3f2fd;
}
.theme--light.v-data-table {
   background-color: white;
}
::v-deep tbody tr:nth-of-type(even) {
   background-color: #f5f5f5; // whitesmoke;
}
::v-deep tbody tr:nth-of-type(odd) {
   background-color: #f8f8f8; // ghostwhite;
}
</style>
